<template>
  <div class="container-main">
    <public-header />
    
    <div class="content-principal">
      <div class="right-form">
        <div class="box-login">
          <!-- Tab de Empresas -->
          <b-alert v-model="showDismissibleAlert" :variant="alertVariant" dismissible>
            <div v-html="serverMessage"></div>
          </b-alert>
          <div class="titles">
            <b>
              {{ $t('Welcome') }},
              <span>{{ $t('Recruiters') }}</span>!
            </b>
          </div>
          <form>
            <div>
              <text-input
                class="text-uppercase"
                variant="dark"
                :label="$t('E-mail')"
                :required="true"
                v-model="usernameEmpresa"
              />
            </div>
            <div>
              <text-input
                class="text-uppercase"
                variant="dark"
                :label="$t('Password')"
                :required="true"
                :type="'password'"
                v-model="passwordEmpresa"
              />
            </div>
            <div class="buttons">
              <button class="btn btn-info entrar" @click.prevent="loginCompany" v-promise-btn="{promise:loginPromise}">{{$t('Enter')}}</button>

              <div class="position-relative my-4">
                <hr />
                <div class="bg-white position-absolute" style="top: 3px;left: 50%;width: 100px;margin-left: -50px;"> {{$t('or access')}} </div>
              </div>
              
              <b-button variant="outline-primary" :href="getv2url" target="_blank">
                <strong>{{$t('New Platform')}}</strong>	
              </b-button>
                            
              <a
                href="#"
                @click.prevent="redirectToEsqueciMinhaSenha"
                class="esqueci-senha"
              >{{ $t('I forgot my password') }}</a>
              <!-- <span>
                {{ $t('I don`t have a registration yet') }}
                <router-link
                  :to="`/register?lang=${$i18n.locale}`"
                >{{ $t('Register now') }}.</router-link>
              </span> -->
            </div>
            <div class="info-captcha">
              <p class="align-right gray">
                {{ $t('This site is protected by reCAPTCHA and the Google') }}
                <br />
                <a
                  href="https://policies.google.com/privacy"
                  target="_blank"
                >{{ $t('Privacy Policy') }}</a>
                {{ $t('and') }}
                <a
                  href="https://policies.google.com/terms"
                  target="_blank"
                >{{ $t('Terms of Service') }}</a>
                
              </p>
            </div>
            <div id="universal_access"></div>
          </form>
        </div>
      </div>
    </div>
    <b-modal :id="'modalOptimizeExp'" 
        size="md" 
        hide-footer 
        hide-header
        >
        <div class="r-font">
          <b-card>
            <b-card-header class="mt-3 bg-white">
              <h1 class="text-center text-blue">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="24" cy="34" r="2" fill="#FF0000"/>
                  <path d="M23.9999 17.9999V27.9999M8.65577 42H39.3442C42.8891 42 45.1298 38.2865 43.4083 35.2648L28.064 8.33184C26.2927 5.22272 21.7073 5.22272 19.936 8.33184L4.59174 35.2648C2.87021 38.2866 5.11092 42 8.65577 42Z" stroke="#FF0000" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

              </h1>
            </b-card-header>
            <b-card-body class="text-center p-0">

            <h1 class="mb-3 text-dark">{{$t("Attention")}} </h1>

            <p > 
              {{$t("This version of the platform will be available until 31/12/2024. From that date onwards, all access will be carried out exclusively through the new version of the platform.")}}
            </p>

            <b-alert show variant="info" class="text-dark text-center">
              <div> {{$t("If you still don't know or have accessed the new platform, click the button below to explore what's new and continue using our services without interruption.")}} </div>
            </b-alert>

            </b-card-body>
          </b-card>
          
          <div class="text-center">
            <b-button variant="white" size="lg" class="mr-1" @click="$bvModal.hide('modalOptimizeExp')"> 
              {{$t("Close")}}
            </b-button> 

            <b-button variant="dark" size="lg" :href="getv2url"> 
              {{$t("Access new platform")}}
            </b-button> 
          </div>
        </div>
      </b-modal>
  </div>
</template>

<script lang="ts">
import TextInput from "@/components/inputs/TextInput.vue";
import PublicHeader from "@/components/layout/PublicHeader.vue";

import ClientAuthentication from '@/core/Services/ClientAuthentication';
import {
siteLandingEndPoint
} from "@/core/endpoint";
import { Component, Vue } from 'vue-property-decorator';
import { Action } from "vuex-class";
import GestaoEmpresa from '../core/Services/Empresa/GestaoEmpresa';

const namespace: string = "profile";
let PIDINTERVAL: any = 0;

@Component({
  components: {
    PublicHeader,
  },
})
export default class Login extends Vue {
  public currentPromise: any | null = null;
  public service: GestaoEmpresa;
  public usernameCandidato: string = "";
  public uri: string = window.document!.location!.protocol + "//" +  window.document!.location!.host;
  public usernameEmpresa: string = "";
  public passwordEmpresa: string = "";
  public myPopUp: Window | null = null;
  public showDismissibleAlert: boolean = false;
  public alertVariant: string = "danger";
  public serverMessage?: string | null = "";
  public loginPromise?: any|null = null;
  public landingURL: string = siteLandingEndPoint;

  @Action("authRequest", { namespace: "profile" }) public authRequest: any;
  @Action("authRequestCompany", { namespace }) authRequestCompany: any;
  @Action("userRequest", { namespace }) public userRequest: any;

  constructor() {
    super()
    this.service = new GestaoEmpresa();
  }

  public created() {
    sessionStorage.removeItem("redirectTo");
  }

  get getv2url() {
    return process.env.VUE_APP_SITE_COMPANYV2_END_POINT;
  }

  public mounted() {
    let token = this.$route.query.acctkn;
    if (token) {
      let data: any = {
        id: 0,
        confirmEmail: true,
        token: token
      };
      this.loginPromise = this.service
        .update(data)
        .then((data: any) => {
          if (data) {
            try {
              this.alertVariant = "success";
              this.serverMessage = this.$t(
                "Your account has been successfully activated!"
              ).toString();
              this.serverMessage +=
                "<br />" +
                this.$t(
                  "Access the platform with your user and password!"
                ).toString();
              this.showDismissibleAlert = true;
            } catch (e) {
              console.log(e);
            }
          }
        })
        .catch((resp: any) => {
          try {
            this.alertVariant = "danger";
            this.showDismissibleAlert = true;
            this.serverMessage = this.$t("There was a problem").toString();
            this.serverMessage += "<br />" + resp.detail;
          } catch (e) {
            console.log(e);
          }
        });
    } 
    setTimeout(() => {
      this.$bvModal.show('modalOptimizeExp')
    }, 1000); 
  }

  public async loginCompany() {
    let token: string|null = null;
    
    try {
      token = await ClientAuthentication.fetchToken('company_login');
    } catch(e) {
      console.log(e)
    }
    if(!token && process.env.NODE_ENV == 'production') {
      this.alertVariant = "danger";
      this.showDismissibleAlert = true;
      this.serverMessage = this.$t("There was a problem").toString();
      this.serverMessage += "<br />" + this.$t("Could not generate re-captcha Token").toString();
      return;
    }
    this.loginPromise = new Promise((resolve) => {

      this.authRequestCompany({
        username: this.usernameEmpresa,
        password: this.passwordEmpresa,
        token
      })
        .then((result: any) => {
          console.log("ok");
          this.showDismissibleAlert = false;
          this.whereToSendCompany();
        })
        .catch((err: any) => {
          console.log(err);
          try {
            this.showDismissibleAlert = true;
            this.alertVariant = "danger";
            this.serverMessage = this.$t("There was a problem").toString();

            if(err.title === 'expired_password'){
              this.$alert.addTitle(this.$t('Attention').toString())
              this.$alert.addMsg(this.$t('Your password has expired! You will be redirected to register a new password!'))
              this.$alert.callBoxWarn().then((v:boolean) => {  
                this.$router.push({ path: '/reset', query: { tkn:err.detail, lang: this.$i18n.locale } });
              });

              this.serverMessage += ": " + this.$t('Senha expirada!');
            } else if(err.title === 'two_factor_confirmation_needed'){
              this.$alert.addTitle(this.$t('Attention').toString())
              this.$alert.addMsg(this.$t('You will receive an e-mail with authentication code! Copy the same and paste the next page!'))
              this.$alert.callBoxWarn().then((v:boolean) => {  
                this.$router.push({ path: '/sf', query: { tkn:err.detail, lang: this.$i18n.locale } });
              });


              this.serverMessage += ": " + this.$t('Inform Second Authentication Factor');
            }else{
              this.serverMessage += ": " + err.detail;
            }

            console.log(err);
          } catch (e) {
            console.log(e);
          }
        })
      .finally(() => {
        // Encerrar promise principal, parando animação no botão
        resolve(true);
      });
    });
  }

  public whereToSendCompany() {
    let rurl: any = this.$route.query.redirectUrl;
    if (rurl && rurl.indexOf('redirectUrl') < 0) {
      sessionStorage.setItem("redirectTo", rurl);
    }
    if (sessionStorage.getItem("access_token")) {
      const path = (rurl && rurl != '/' && rurl.indexOf('/?') == -1 ) ? rurl : "/home";
      this.$router.push({ path: path, query: { lang: this.$i18n.locale } });

    }
  }

  public checkClosedWindow(w: Window | null, func: Function) {
    if (w !== null && w.closed) {
      clearInterval(PIDINTERVAL);
      func.call(0);
    }
  }

  public redirectToEsqueciMinhaSenha() {
    const frontBase = '';
    window.location.href = `${frontBase}/forgot?lang=${this.$i18n.locale}`;
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/main.scss";
@import "@/assets/scss/_variables";

.container-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;
  background-color: #fafafa !important;
  font-family: nerislight;

  .content-principal {
    display: flex;
    margin: 0 0 32px 0;
    max-width: 40%;
    height: calc(100vh - 8em);
    width: calc(100% - 15em);

    -webkit-box-shadow: 0px 0px 53px 5px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 53px 5px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 53px 5px rgba(0,0,0,0.2);

    border-radius: 20px;

    .left-image {
      display: flex;
      width: 55%;
      .img {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-image: url("https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/utils/svg/login_empresa.svg");
        h1 {
          font-size: 3em;
          color: white;
          text-align: center;
          font-weight: bold;
          margin: 0.5em 1em;
        }
      }
    }
    .right-form {
      width: 100%;
      background-color: white;
      padding: 2em;
      height: 100%;

      border-radius: 20px;

      .box-login {
        // font-size: .8em;
        width: 100%;
        height: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        padding: 1.0em 1.0em;
        justify-content: center;
        align-items: center;

        form {
          width: 70%;

          @media screen and (max-width: 1030px) {
            width: 100%;
          }

          font-size: 1.3em;
          label {
            font-size: 0.65em;
            margin: 0;
          }
        }
        .titles {
          text-align: center;
          font-size: 1.5em;
          margin: 1.5em;
          p,
          b {
            margin-bottom: 0;
            line-height: 1;
            font-size: 1.5em;
          }
          b span {
            color: $blue;
          }
        }
        .buttons {
          margin-top: 1em;
          display: flex;
          flex-direction: column;
          text-align: center;
          a,
          span {
            margin: 0.5rem 0;
          }
          .btn {
            height: 2.5em;
            font-size: 1.25em;
            margin: 0.1em 4em;
            &.entrar {
              background-color: $green-light !important;
              border-color: $green-light !important;
            }
            &.facebook {
              background-color: #066fb6 !important;
              border-color: #066fb6 !important;
            }
            &.linkedin {
              background-color: $blue !important;
              border-color: $blue !important;
            }
            i {
              font-size: 1.5em;
              position: absolute;
              left: 4em;
            }
          }
        }
        .info-captcha {
          p {
            text-align: right;
          }
        }
      }
    }
  }
}
@include media(">desktop") {
  .container-main {
    font-size: 0.8em;
  }
  .info-captcha {
    p {
      font-size: 0.7em;
    }
  }
}
@include media("<desktop") {
  .container-main {
    .content-principal {
      height: 100%;
      width: 100%;
      .left-image {
        display: none !important;
      }
      .right-form {
        width: 100%;
        padding: 0em;
        .box-login {
          padding: 1em;
          form {
            font-size: 0.7em;
          }
          .buttons {
            .btn {
              height: 3em;
              font-size: 1.5em;
              margin: 0;
              margin-bottom: 0.3em;
              font-weight: bold;
              i {
                left: 2em;
              }
            }
            a.esqueci-senha,
            span {
              font-size: 1.5em;
            }
          }
        }
      }
    }
  }
}
</style>